// Customizable Area Start
import { Dimensions, PixelRatio, Platform } from "react-native";
import { responsiveWidth } from "react-native-responsive-dimensions";

import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import { getCustomerId, getToken, logout as StorageLogout, getCart, saveCart, getCustomerType, getProfileId, saveCartConfirm} from "../../../components/src/utilities/StorageHelper";
import { ICart, ICartProduct, IProduct } from '../../../components/src/types/types'
import { formatPrice } from "../../../components/src/utilities/utils";

export default class MergeEngineUtilities {
  static _token = '';
  static _customer_id = '';
  static _profile_id = '';
  static _customer_type: 'R' | 'E';
  static _CART: ICart = {
    totalPrice: 0,
    products: {},
    prices: {},
    productDetails: {}
  }
  static isCartInitated = false;
  static isLanguageTextsFetched = false;
  static _screenWidth = Dimensions.get("window").width;
  static _screenHeight = Dimensions.get("window").height;
  //Artboard Dimension
  static _artBoardHeightOrg = 667;
  static _artBoardWidthOrg = 375;
  //Re calculated Artboard Dimension
  static _artBoardWidth = MergeEngineUtilities.isSameRatio()
    ? MergeEngineUtilities._artBoardWidthOrg
    : MergeEngineUtilities._screenWidth;
  static _artBoardHeight = MergeEngineUtilities.isSameRatio()
    ? MergeEngineUtilities._artBoardHeightOrg
    : MergeEngineUtilities._screenHeight;
  //Top or Bottom nav spaces or any extra space occupied by os e.g Status bar, Notch
  static _extraSpace = 0;
  // To check if Artboard and Device screen has same ratio
  static isSameRatio(): boolean {
    return (
      MergeEngineUtilities._artBoardWidthOrg /
        MergeEngineUtilities._artBoardHeightOrg <
        1 &&
      MergeEngineUtilities._screenWidth / MergeEngineUtilities._screenHeight < 1
    );
  }

  static init(
    artBoardHeightOrg: number,
    artBoardWidthOrg: number,
    screenHeight: number,
    screenWidth: number
  ) {
    MergeEngineUtilities._artBoardHeightOrg = artBoardHeightOrg;
    MergeEngineUtilities._artBoardWidthOrg = artBoardWidthOrg;
    MergeEngineUtilities._screenHeight = screenHeight;
    MergeEngineUtilities._screenWidth = screenWidth;
  }

  static deviceBasedDynamicDimension(
    originalDimen: number,
    compareWithWidth: boolean,
    resizeFactor: number
  ): number {
    if (originalDimen != null) {
      if (resizeFactor != null) {
        originalDimen *= resizeFactor;
      }
      const compareArtBoardDimenValue = compareWithWidth
        ? MergeEngineUtilities._artBoardWidth
        : MergeEngineUtilities._artBoardHeight;
      const artBoardScreenDimenRatio =
        (originalDimen * 100) / compareArtBoardDimenValue;
      let compareCurrentScreenDimenValue = compareWithWidth
        ? MergeEngineUtilities._screenWidth
        : MergeEngineUtilities._screenHeight - MergeEngineUtilities._extraSpace;
      if (Platform.OS === "web") {
        if (originalDimen > responsiveWidth(originalDimen/compareCurrentScreenDimenValue) ) {
          return(responsiveWidth(originalDimen/compareCurrentScreenDimenValue)*100); 
        } else{
            return(originalDimen);
        }
      }
      return PixelRatio.roundToNearestPixel(
        (artBoardScreenDimenRatio * compareCurrentScreenDimenValue) / 100
      );
    }
    return 0;
  }

  static createRequestMessage({
    endpoint,
    method,
    data,
    header,
    useToken
  }: {
    endpoint: string,
    method: 'POST' | 'GET' | 'PATCH' | 'DELETE' | 'PUT',
    data?: any,
    header?: Record<string, any>,
    useToken?: boolean
  }) {

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${endpoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    if (data) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
    }

    if (header) {
      const headerData: any = { ...header, "Content-Type": 'application/json; charset=utf-8' }
      if (useToken) { headerData.token = this._token; }

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headerData)
      );
    }

    return requestMessage;
  }

  static navigateToScreen(screenName:string, props:any, data?: any) {
    const navigationMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    navigationMessage.addData(
    getName(MessageEnum.NavigationPropsMessage), props);

    if (data) {
      const raiseMessage = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.NavigationData), data);
      navigationMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage)
    }

    runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
  }

  static reRenderBlock(data?: any) {
    const navigationMessage = new Message(getName(MessageEnum.NavigationReRender));

    if (data) {
      const raiseMessage = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.NavigationData), data);
      navigationMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage)
    }

    runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
  }

  static async validateToken(props?: any): Promise<string> {
    const token = await getToken();
    if (!token) {
      if (props) { this.logout(props) }
      return '';
    }
    await this.loginWhileValidate()

    const validateTokenApiMethod = 'bx_block_forgot_password/token_confirmations';

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${validateTokenApiMethod}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({ "Content-Type": 'application/json' })
    );      

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: { token } })
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  }

  static async getNotification(): Promise<string> {
    const getNotificationApiMethod = 'bx_block_notifications/notifications';

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${getNotificationApiMethod}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({ "Content-Type": 'application/json' })
    );      

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({ token: localStorage.getItem("authToken") })
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  }
  static fetchLanguageTexts(): string {
    if (!this.isLanguageTextsFetched) { return ''; }

    const requestMessage = this.createRequestMessage({
      endpoint: `bx_language`,
      method: 'GET',
      header: {},
      useToken: true,
    })

    const callId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return callId;
  }

  static handleLanguageResponse(responseJson: any) {
    // TODO: Set the texts here.
  }

  static isLoggedIn() : boolean {
    return !!(MergeEngineUtilities._token && MergeEngineUtilities._customer_type)
  }

  static async loginWhileValidate() {
    const token = await getToken();
    const customer_id = await getCustomerId();
    const profile_id = await getProfileId();
    const customer_type = await getCustomerType();
    MergeEngineUtilities._token = token;
    MergeEngineUtilities._customer_id = customer_id;
    MergeEngineUtilities._profile_id = profile_id;
    MergeEngineUtilities._customer_type = customer_type;
  }

  static async logout(props: any) {
    await StorageLogout();
    MergeEngineUtilities._token = '';
    MergeEngineUtilities._customer_id = '';
    MergeEngineUtilities._profile_id = '';
    MergeEngineUtilities._customer_type = '' as any;

    this.navigateToScreen('EmailAccountLoginBlock', props);
  }

  static async initCart() {
    const cart = await getCart();
    cart.productDetails = {};
    cart.prices = {}
    cart.totalPrice = 0;

    this._CART = { products: { ...cart.products }, prices: {}, totalPrice: 0, productDetails: {} };
    this.isCartInitated = true;
    return this._CART;
  }

  static async updateCart(
    productId: ICartProduct['product_id'],
    qty: number,
    operation: 'DELETE' | 'UPDATE',
  ) {
    const cartItem = this._CART.products[`id_${productId}`];
    if (operation === 'DELETE') {
      delete this._CART.products[`id_${productId}`];
    } else if (operation === 'UPDATE') {
      if (cartItem && qty <= 0) {
        delete this._CART.products[`id_${productId}`];
      } else if(cartItem) {
        this._CART.products[`id_${productId}`].cart_quantity=qty;
      } else {
        this._CART.products[`id_${productId}`] = { product_id: productId, cart_quantity: qty }
      }
    }

    await saveCart(this._CART)

    return this._CART;
  }

  static async addProductDetailsToCart(cartResponse: any) {
    const cart = { ...this._CART };
    cart.totalPrice = cartResponse.total_sum;
    
    cartResponse.order_items.data.forEach((product: any) => {
      const pid = product.attributes.catalogue.data.attributes.product_id;

      cart.productDetails[`id_${pid}`] = {
        ...product.attributes.catalogue.data.attributes,
        id: pid
      };

      cart.prices[`id_${pid}`] = product.attributes.price;
    });

    await saveCart(cart);
    this._CART = cart;
    return this._CART;
  }

  static async addProductDetailsToCartSubmitData(cartResponse: any) {
    const cart = { ...this._CART };
    cart.totalPrice = cartResponse?.total_sum;
    
    cartResponse?.order_items?.data.forEach((product: any) => {
      const pid = product?.attributes?.catalogue?.data?.attributes?.product_id;

      cart.productDetails[`id_${pid}`] = {
        ...product?.attributes?.catalogue?.data?.attributes,
        id: pid
      };

      cart.prices[`id_${pid}`] = product?.attributes?.price;
    });

    await saveCartConfirm(cart);
    this._CART = cart;
    return this._CART;
  }


  static async addProductDetailsToCartData(cartResponse: any) {
    const cart = { ...this._CART };
    cart.totalPrice = cartResponse.total_sum;
    
    cartResponse.quote_order_items.forEach((product: any) => {
      const pid = product.product_id;

      cart.productDetails[`id_${pid}`] = {
        ...product,
        id: pid
      };

      cart.prices[`id_${pid}`] = product.product_price;
    });
    await saveCartConfirm(cart);
    this._CART = cart;
    return this._CART;
  }

  static checkCartStock() {
    const cart = this._CART;

    const hasStockIssue = Object.entries(cart.products).some(([pid, product]) => {
      const data = cart.productDetails[pid];
      return product.cart_quantity > data.stock_qty;
    });

    return hasStockIssue;
  }

  static checkUnit() {
    const cart = this._CART;

    if (Object.values(cart.products).length === 0) {
      return false;
    }

    const firstPid = Object.keys(cart.products)[0];
    const firstBaseUnit = cart.productDetails[firstPid].base_unit;

    const isAllUnitsSame = Object.keys(cart.products).every((pid) => {
      const data = cart.productDetails[pid];
      return data.base_unit === firstBaseUnit;
    });

    return isAllUnitsSame;
  }

  static async cleanCart() {
    this._CART = { products: {}, prices: {}, totalPrice: 0, productDetails: {} }
    await saveCart(this._CART);

    return this._CART
  }

  static async getCartCount() {
    let c = this._CART

    if (!this.isCartInitated) {
      c = await this.initCart();
    }

    return Object.keys(c.products).length || 0;
  }

  static getProductPrice<T extends string | number>(product: IProduct, returnStr: boolean = true): T {
    const price = this._customer_type === 'E' ? product.engineering_price : product.reseller_price;
    return (returnStr ?
        formatPrice(price)
        : parseFloat(parseFloat(price).toFixed(2))
      ) as T
  }
}
// Customizable Area End
