import React from 'react';
// Customizable Area Start
import { Grid, Chip, Typography } from '@material-ui/core';
import { classNames } from '../../../components/src/utilities/utils';
import { withTranslation } from 'react-i18next';

const TEXTS: Record<IProps['filterType'], string> = {
    MATERIAL: 'Material',
    DIAMETER: 'Diameter',
    SCH: 'Sch',
}
// Customizable Area End
class Filters extends React.Component<IProps, IState> {
    // Customizable Area Start
    constructor(props: IProps) {
        super(props);

        this.onItemClicked = this.onItemClicked.bind(this);
    }

    onItemClicked(item: IFilterItem, idx: number) {
        this.props.onItemClicked(item.id, this.props.filterType)
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { filterItems, valueIds, filterType, t } = this.props;

        return (
             <div className="filter-items">
                <Typography className="filter-title">{t(TEXTS[filterType])}</Typography>

                <Grid
                    container
                    spacing={2}
                >
                    {filterItems.map((item, idx) => (
                        <Grid item key={item.id}>
                            <Chip
                                label={item.name}
                                onClick={() => this.onItemClicked(item, idx)}
                                color="primary"
                                className={classNames({
                                    '__chip-item': true,
                                    '__is-selected': valueIds.includes(item.id)
                                })}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
        )
        // Customizable Area End
    }
}
// Customizable Area Start
interface IFilterItem {
    id: number,
    name: string,
}

interface IProps {
    filterItems: IFilterItem[],
    filterType: 'MATERIAL' | 'DIAMETER' | 'SCH'
    valueIds: number[]
    onItemClicked: (itemId: number, type: IProps['filterType']) => void
    t:any
}

interface IState {
}
export {Filters}
export default withTranslation()(Filters);
// Customizable Area End